import React from 'react';
import { Form, notification } from 'antd';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

import { Button } from '../../../sharedComponents/';
import generateWalletFile from './generateWalletFile';

const REGISTER_USER = loader('../../../../../services/hasura/mutations/registerUser.graphql');
const LOGIN_USER = loader('../../../../../services/hasura/mutations/login.graphql');

export default function CreateWallet({ emailUserInput = '', setMemberrWalletDetails, setShowCreateWalletFlow }) {
  const [form] = Form.useForm();

  const [registerUser, { data: registerUserData, loading: registerUserLoading }] = useMutation(REGISTER_USER);
  const [login, { data: loginData, loading: loginLoading, error: loginError }] = useMutation(LOGIN_USER);

  /*
  const downloadWalletFile = ({ walletObject, walletFileName }) => {
    // download wallet file
    exportFromJSON({ data: walletObject, fileName: walletFileName, exportType: exportFromJSON.types.json });
  };
  */

  console.log({ emailUserInput });
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      console.log({ values });

      const { email, password, passwordConfirm } = values;

      if (password !== passwordConfirm) {
        throw new Error('Passwords do not match!');
      }

      const { walletFile, walletFileName, walletObject, address, privateKey } = generateWalletFile({ password });

      const variables = {
        email,
        password,
        walletFile,
      };

      try {
        console.log('now register user');
        console.log({ variables });
        const result = await registerUser({ variables });

        console.log({ result });

        const {
          data: {
            registerUser: { id, error: registerUserError },
          },
        } = result;

        if (!registerUserError) {
          setMemberrWalletDetails({
            address,
            privateKey,
            walletFile,
            password,
          });
          return;
        }

        if (registerUserError === 'That email address is already registered') {
          console.log(registerUserError);
          console.log('now login user');
          const result = await login({ variables: { email, password } });
          console.log({ result });

          const {
            data: {
              login: { id, error: loginError },
            },
          } = result;

          if (!loginError) {
            console.log('TODO: now set wallet of user!');
          }

          if (loginError === 'E-Mail and password do not match') {
            notification.error({
              message: 'Authentication error',
              description: 'Account already exists, but with a different password!',
            });
          }

          return;
        }
      } catch (_error) {
        console.log('register user error');
        console.error(_error);
        return;
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: error.message,
      });
    }
  };

  return (
    <div className="p-4 bg-black bg-opacity-60 rounded-xl">
      <h3 className="text-center text-white my-2" style={{ fontSize: '20px' }}>
        Create Wallet
      </h3>
      <Form
        form={form}
        initialValues={{
          email: emailUserInput,
        }}
        layout="vertical"
        className="w-full"
      >
        <label className="block text-white" htmlFor="twitter_handle">
          E-Mail
        </label>
        <Form.Item name="email" rules={[{ required: true, message: 'Please enter a valid email address' }]}>
          <input
            placeholder="example@gmail.com"
            className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
          />
        </Form.Item>
        <label className="block text-white" htmlFor="password">
          Password
        </label>
        <Form.Item name="password" rules={[{ required: true, message: 'Please enter a password' }]}>
          <input
            placeholder="Password"
            type="password"
            className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
          />
        </Form.Item>
        <label className="block text-white" htmlFor="passwordConfirm">
          Confirm Password
        </label>

        <Form.Item
          name="passwordConfirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match!'));
              },
            }),
          ]}
        >
          <input
            placeholder="Confirm Password"
            type="password"
            className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
          />
        </Form.Item>
        <div className="justify-between mt-2">
          <Button onClick={handleSubmit}>Create memberr Wallet</Button>
        </div>
        <div className="justify-between mt-2">
          <button
            style={{ border: '1px solid', borderColor: 'rgb(265, 256, 256, 0.56)', borderRadius: '10px' }}
            className="w-full mt-5 px-12 py-4 cursor-pointer tex-center text-white text-lg font-bold bg-black transform transition duration-200 hover:scale-105"
            onClick={() => setShowCreateWalletFlow(false)}
          >
            Cancel
          </button>
        </div>
      </Form>
    </div>
  );
}
