import React from 'react';

import Spinner from './Spinner';

export default function Button({ onClick, className, loading, children, ...rest }) {
  if (loading) {
    return (
      <button
        className={`mt-5 w-full px-12 py-4 rounded-lg cursor-not-allowed text-white text-lg font-bold bg-blue-500 transform transition duration-200 hover:scale-105 ${className}`}
        {...rest}
      >
        {loading && <Spinner />}
        {children}
      </button>
    );
  }

  return (
    <button
      onClick={onClick}
      className={`mt-5 w-full px-12 py-4 rounded-lg cursor-pointer text-white text-lg font-bold bg-blue-700 hover:bg-blue-800 transform transition duration-200 hover:scale-105 ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
}
