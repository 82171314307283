import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

import GqlClient from './services/hasura/GqlClient';

import App from './App';
import './index.css';

ReactDOM.render(
  <GqlClient>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </GqlClient>,
  document.getElementById('root'),
);
